import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import HallSessions from "./HallSessions";

export const HALL_CATEGORY_1: Array<ProgrammeSession["hall"]> = ["BALLROOM C"];

const EVENT_DATES = [
  { dayNum: 1, dayName: "Day 1 (Thu)", date: "20th Feb 2025" },
  { dayNum: 2, dayName: "Day 2 (Fri)", date: "21st Feb 2025" },
  { dayNum: 3, dayName: "Day 3 (Sat)", date: "22nd Feb 2025" },
];

export const FACULTY_TYPE = [
  "Chairperson",
  "Citation",
  "Co-Chair",
  "Emcee",
  "Judge",
  "Guest Of Honor",
  "Speaker",
  "Adjudicator",
  "Moderator",
  "Facilitator",
  "Facilitators",
  "Panel",
  "Surgeon",
  "Debate Team A",
  "Debate Team B",
] as const;

export enum CATEGORY {
  "ballroom_c",
}

export interface Faculty {
  id: number;
  fullName: string;
  avatar: string;
  shortProfile: string;
}

export interface FacultyWithType extends Faculty {
  facultyType: (typeof FACULTY_TYPE)[number];
}

export interface ProgrammeSession {
  id: number;
  title: string;
  desc?: string;
  startAt: string;
  endAt: string;
  date: string; // 11 JUN | SATURDAY
  time: string; // 10:00 to 11:00
  day: 1 | 2 | 3;
  hallOverwrite?: string;
  hall: "BALLROOM C";
  category: "ballroom_c";
  faculties: Array<FacultyWithType>;
  subsessions: Array<ProgrammeSubsession>;
  secondsTillStart: number;
  durationInSeconds: number;
  ended?: boolean;
}

export interface ProgrammeSubsession {
  id: number;
  title?: string;
  description?: string;
  speakers: Array<Faculty>;
  time: string; // 10:00 to 11:00
}

const Programme = ({ defaultDay }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<CATEGORY>(CATEGORY.ballroom_c);
  const [day, setDay] = useState(defaultDay || 1);
  const [data, setData] = useState([]);

  const [activeLecture, setActiveLecture] =
    useState<ProgrammeSession["hall"]>("BALLROOM C");

  useEffect(() => {
    setLoading(true);
    axios
      .get(`/api/programme_sessions?day=${day}`)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [day, category]);

  const dayClass = "btn day-btn my-1 fw-bold me-2";

  const getTabColor = (hallname: string) => {
    switch (hallname) {
      case "BALLROOM C":
        return "#2F50A1";
      default:
        return "";
    }
  };

  if (loading) {
    return (
      <div className="loader">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="container pb-5" id="programmeAgenda">
      <div className="filterBtns">
        {/* 1st row */}
        <div className="my-3 text-center">
          {EVENT_DATES.map(({ dayNum, dayName, date }) => (
            <button
              key={dayNum}
              className={`${dayNum === day && "active"} ${dayClass}`}
              onClick={() => {
                setDay(dayNum);
                setActiveLecture("BALLROOM C");
              }}
            >
              <div>{dayName}</div>
              <div>{date}</div>
            </button>
          ))}
        </div>
      </div>

      {/* 3rd row */}
      <div className="col-12 col-lg-8 mx-auto">
        {category === CATEGORY.ballroom_c && (
          <div className="mt-5">
            <div className="d-block d-md-none">
              {HALL_CATEGORY_1.map((hall) => (
                <button
                  key={hall}
                  className={`hallTabs fw-bold ${
                    activeLecture !== hall && "deselected"
                  }`}
                  style={{
                    background: getTabColor(hall),
                    textTransform: "capitalize",
                  }}
                  onClick={() => setActiveLecture(hall)}
                >
                  {hall.replace("Lecture ", "")}
                </button>
              ))}
            </div>

            <div className="row nogutters">
              {HALL_CATEGORY_1.map((hall) => (
                // <div className={day === 2 ? "col-md-6" : "col-md-4 px-2"} key={hall}>
                <div key={hall}>
                  <HallSessions
                    hallName={hall}
                    activeHall={activeLecture}
                    programmes={data.filter(
                      (programme: ProgrammeSession) => programme.hall === hall
                    )}
                    getTabColor={getTabColor}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Programme;
